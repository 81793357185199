import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { InternalAxiosRequestConfig } from "axios";
import axios from "axios";
import "core-js/features/object/group-by";
import "core-js/features/promise/with-resolvers";
import { jwtDecode, type JwtPayload } from "jwt-decode";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import AppSetup from "../components/layout/AppLayout/AppSetup";
import AuthSetup from "../components/layout/AuthLayout/AuthSetup";
import { REFETCH_INTERVAL_PERIODIC } from "../config/refetch";
import getPublicRuntimeConfig from "../utils/getPublicRuntimeConfig/getPublicRuntimeConfig";
import "react-toastify/dist/ReactToastify.css";
import "tippy.js/dist/tippy.css";
import structuralSharing from "../utils/structuralSharing/structuralSharing";

export type NextPageWithLayout<
  Props = Record<string, unknown>,
  InitialProps = Props,
> = NextPage<Props, InitialProps> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export interface JwtClaims extends JwtPayload {
  groups: readonly string[];
  name: string;
  sub: string;
  uid: string;
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const [oktaAuth] = useState(() => {
    const { NEXT_APP_DOMAIN, NEXT_OKTA_CLIENT_ID, NEXT_OKTA_ISSUER } =
      getPublicRuntimeConfig();
    const _oktaAuth = new OktaAuth({
      issuer: NEXT_OKTA_ISSUER,
      clientId: NEXT_OKTA_CLIENT_ID,
      redirectUri: NEXT_APP_DOMAIN,
      scopes: ["email", "profile", "openid", "offline_access"],
    });

    axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      if (!config.url) return config;

      const baseURL = new URL(getPublicRuntimeConfig().REST_API_URL);
      // eslint-disable-next-line no-param-reassign
      config.baseURL = baseURL.toString();

      const url = new URL(config.url, baseURL);
      if (url.origin !== baseURL.origin) return config;

      const accessToken = _oktaAuth.getAccessToken();
      if (!accessToken) return config;

      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;

      if (
        getPublicRuntimeConfig().ENVIRONMENT === "local" &&
        // If we happen to be pointing at a deployed environment, we don't want to send the
        // Impersonate header as it will fail due to CORS issues - only permitted locally
        !baseURL.origin.endsWith(".rfs.nsw.gov.au")
      ) {
        const { groups, name, sub, uid } = jwtDecode<JwtClaims>(accessToken);
        // eslint-disable-next-line no-param-reassign
        config.headers.Impersonate = `email=${sub};name=${JSON.stringify(name)};uid=${uid};roles=${JSON.stringify(groups.join(","))}`;
      }

      return config;
    });

    axios.interceptors.response.use(
      (response) => response,
      (error: unknown) => Promise.reject(error),
    );

    return _oktaAuth;
  });

  const { replace } = useRouter();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: REFETCH_INTERVAL_PERIODIC,
            structuralSharing,
          },
        },
      }),
  );

  // https://nextjs.org/docs/basic-features/layouts#with-typescript
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  const restoreOriginalUri = useCallback(
    async (_oktaAuth: OktaAuth, originalUri: string) => {
      const { NEXT_APP_DOMAIN } = getPublicRuntimeConfig();
      await replace(toRelativeUrl(originalUri, NEXT_APP_DOMAIN));
    },
    [replace],
  );

  return (
    <AppSetup>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <AuthSetup>
          <QueryClientProvider client={queryClient}>
            {getLayout(<Component {...pageProps} />)}
          </QueryClientProvider>
        </AuthSetup>
      </Security>
    </AppSetup>
  );
};

App.getInitialProps = () => {
  // NOTE: Including `getInitialProps` on `App` here so that we force SSR on every page. This
  // disables SSG and allows us to reference `publicRuntimeConfig` properly from client code.
  return {};
};

export default App;
